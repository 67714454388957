.header .content {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.logo {
    font-size: 2rem;
    font-weight: 700;
}

.header-links {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.mode {
    min-width: 2rem;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
}

.mode i {
    font-size: 1.8rem;
    cursor: pointer;
}

.mode .bars {
    display: none;
}

@media (max-width: 540px) {
    .mode .bars {
        display: block;
    }

    .header-links {
        position: absolute;
        right: 0;
        top: 80px;
        flex-direction: column;
        width: 8rem;
        padding: 2rem;
        background-color: var(--color-white);
        box-shadow: 0 0 10px 0 var(--text-black-50);
        transform-origin: top right;
        z-index: 10;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        -ms-border-radius: 1rem;
        -o-border-radius: 1rem;
        border-radius: 1rem;
}

    .scale-sm-0 {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }
}