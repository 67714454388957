.projects{
    padding: 3rem 0;
}

.projects .title{
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    padding-bottom: 2rem;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid #cccccc;
}

.projects .content{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

.projects .content .item{
    box-shadow: 0 0 10px 0 var(--text-black-50);
    overflow: hidden;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
}

.projects .content .item img{
    width: 100%;
}

.projects .content .item .item-title{
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
}

.projects .content .item .item-desc{
    padding: 0 1rem 1rem 1rem;
    line-height: 1.5rem;
}

.projects .content .item .item-desc span{
    color: #666666;
    font-weight: 400;
}

.projects .content .item .view{
    padding: 0 1rem 1rem 1rem;
}

.projects .content .item .view a{
    color: var(--main-color);
}