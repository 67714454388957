.contact{
    padding: 3rem 0;
}

.contact .title{
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2.5rem;
}

.contact form{
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact form input,
.contact form textarea{
    padding: 1rem;
    outline: none;
    resize: none;
    border: 1px solid #cccccc;
    background-color: transparent;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
}

.contact form input:focus,
.contact form textarea:focus{
    box-shadow: 0 0 10px 0 #cccccc;
}

.contact .wrong-data-message,
.contact .correct-data-message{
    padding: 1rem;
    margin: .5rem 0;
    color: white;
    display: none;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
}

.contact .wrong-data-message i,
.contact .correct-data-message i{
    color: white;
    margin-right: .5rem;
}

.contact .wrong-data-message{
    background-color: red;
}

.contact .correct-data-message{
    background-color: var(--main-color);
}

.contact form button{
    outline: none;
    border: none;
    width: 10rem;
    padding: 1rem;
    margin: .5rem auto 0;
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
}