.footer{
    padding: 1rem 0;
}

.footer-links{
    display: flex;
    justify-content: center;
    gap: 2rem;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.copy-right{
    text-align: center;
    color: #707070;
}
