:root{
  --main-color: #00e7b2;
  --color-black: black;
  --color-white: white;
  --text-black-50: #00000080;
  --text-white-50: #ffffff80;
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: var(--color-black);
}

::-webkit-scrollbar {
  display: none;
}

html{
  font-size: 15px;
  scroll-behavior: smooth;
}

body{
  font-family: 'Cairo', sans-serif;
  background-color: var(--color-white);
  font-family: 'Edu NSW ACT Foundation', cursive;
  font-family: 'Tillana', cursive;
}

#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

ul{
  list-style: none;
}

a{
  text-decoration: none;
  display: inline-block;
}

/* Start Component */

.container{
  padding: 0 1rem;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .container {
      width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
      width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
      width: 1170px;
  }
}

.d-none{
  display: none !important;
}
/* End Component */
