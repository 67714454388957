.skills{
    padding: 3rem 0;
}

.skills .title{
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    padding-bottom: 2rem;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid #cccccc;
}

.skills .tech{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.skills .tech .box{
    width: 120px;
    height: 120px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    box-shadow: 0 0 10px 0 var(--text-black-50);
    font-weight: 600;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
}

.skills .tech .box img{
    width: 2.5rem;
}