.home{
    padding: 3rem 0;
}

.home .name{
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
}

.home .email{
    text-align: center;
}

.home .email a{
    font-size: 1.2rem;
    color: var(--main-color);
    cursor: default;
}

.home .about{
    margin: 3rem 0;
    line-height: 2rem;
}

.home .about p:nth-child(1){
    margin-bottom: 1rem;
}
.home .current-building .title{
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.home .current-building .list{
    padding-left: 1rem;
    list-style: inside;
    line-height: 2rem;
}

.home .current-building .list a{
    color: var(--main-color);
    text-decoration: underline;
}